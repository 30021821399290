import { defineRoute } from "../../../util/typedRouting";

export const completeCustomerProfilePageRoute = defineRoute<{
  state: {
    action: "submit" | "join_waitlist" | "apply_to_program";
    program_id?: string;
    student_ids?: number[];
  };
}>({
  path: "/schedule/complete-customer-profile",
  build: (path) => path,
});
