import { defineRoute } from "../../util/typedRouting";
import { IBookingResult } from "./ScheduleSharingPageVM";

export const scheduleSharingRoute = defineRoute<{
  state: Partial<{
    bookingResult: IBookingResult;
    routeFrom: string;
  }>;
}>({
  path: "/schedule-sharing",
  build: (path) => path,
});
