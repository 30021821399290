import { CreditIcon } from "@sizdevteam1/funjoiner-uikit";
import classNames from "classnames";

const CreditRowWithQuantityEnd: React.FC<{
  name: string;
  isFree: boolean;
  isProgram: boolean;
  quantity: number;
  className?: string;
}> = ({
  name,
  isFree: is_free,
  isProgram: is_program,
  quantity,
  className,
}) => {
  return (
    <div
      className={classNames(
        "flex items-center justify-between gap-1.5",
        className,
      )}
    >
      <div className="flex flex-col gap-0.5">
        <div className={"typography-h4 text-text-color"}>{name}</div>
        <div
          className={classNames(
            "text-small flex items-center text-gray-text-color",
          )}
        >
          {is_free && "Free "}
          {is_program ? "Program" : "Session"} Credit
        </div>
      </div>
      <div className="flex gap-0.5">
        <CreditIcon size={24} isFree={is_free} isProgram={is_program} />
        <div className={"typography-main_sb"}>×&nbsp;{quantity}</div>
      </div>
    </div>
  );
};

export default CreditRowWithQuantityEnd;
