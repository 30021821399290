import { action, computed, makeObservable, reaction } from "mobx";
import AuthStore from "./AuthStore";
import CommonStore from "./CommonStore";
import CustomerStore from "./CustomerStore";
import DocumentsStore from "./DocumentsStore";
import PaymentStore from "./PaymentStore";
import RouterStore, { RouterStoreProps } from "./RouterStore";
import FunboxStore from "./FunboxStore";
import PaymentMethodStore from "./PaymentMethodStore";
import { QuestionSetStore } from "./QuestionSetStore";
import api from "../services/api";
import notificator from "../services/systemNotifications/notificationCenterService";
import { initPendo } from "../services/pendo-service";
import {
  ERROR_CODES,
  isApplicationError,
} from "@sizdevteam1/funjoiner-web-shared/services/FunjoinerRestAPI";
import apiService from "../services/api/apiService";

export default class RootStore {
  public routerStore: RouterStore;
  public paymentStore: PaymentStore;
  public paymentMethodStore: PaymentMethodStore;
  public authStore: AuthStore;
  public commonStore: CommonStore;
  public funboxStore: FunboxStore;
  public documentsStore: DocumentsStore;
  public customerStore: CustomerStore;

  public questionSetStore: QuestionSetStore;

  constructor(routerStoreProps: RouterStoreProps) {
    makeObservable(this);
    this.authStore = new AuthStore();
    this.commonStore = new CommonStore();
    this.paymentStore = new PaymentStore(this);
    this.funboxStore = new FunboxStore(this);
    this.routerStore = new RouterStore(routerStoreProps);
    this.customerStore = new CustomerStore(this);
    this.paymentMethodStore = new PaymentMethodStore(
      this.paymentStore,
      this.funboxStore,
    );
    this.documentsStore = new DocumentsStore(this);
    this.questionSetStore = new QuestionSetStore(() => {
      this.customerStore.loadSmartForms();
      this.customerStore.loadApplications();
    });

    reaction(
      () => this.authStore.loggedIn,
      async (loggedIn) => {
        if (loggedIn) {
          await this.initPrivate();
        } else {
          this.clearAll();
        }
      },
      { fireImmediately: true },
    );
    this.initPublic();
    console.log(this);
    // @ts-expect-error - for debugging purposes
    window["rootStore"] = this;
  }

  @action.bound
  async initPublic() {
    try {
      const data = await api.common.initRequest();

      this.commonStore.initWith(data);
      await this.funboxStore.initWith(data.funboxes);
    } catch (e) {
      notificator.error("Application Failed to Initialize");
      throw e;
    }
  }

  @computed
  get initialized() {
    return (
      this.authStore.initialized &&
      this.customerStore.initialized &&
      this.documentsStore.initialized
    );
  }

  @computed
  get publicInitialized() {
    return (
      this.authStore.initialized &&
      this.commonStore.initialized &&
      this.funboxStore.initialized
    );
  }

  @computed
  get showAppLoading() {
    return (
      !this.publicInitialized || (this.authStore.loggedIn && !this.initialized)
    );
  }

  @action
  private initPrivate = async () => {
    await Promise.all([
      this.customerStore.init(),
      this.paymentStore.init(),
      this.paymentMethodStore.sync(),
      this.documentsStore.loadDocuments(),
    ])
      .then(() => {
        initPendo(
          this.customerStore.customer,
          window.location.hostname.split(".")[0],
        );
        console.log("Application Initialized");
      })
      .catch((e) => {
        console.log("Failed to initialize the App");
        console.error(e);
        this.authStore.signOut();
      });
  };

  @action
  private clearAll = () => {
    this.customerStore._customer = null;
  };
  private setupGlobalErrorHandling = () => {
    apiService.onError((e) => {
      if (!isApplicationError(e)) return;
      switch (e.code) {
        case ERROR_CODES.NO_SUCH_COMPANY:
          window.location.href = e.payload.redirect;
          break;
        case ERROR_CODES.COMPANY_LOCKED:
          this.commonStore.isCompanyLocked = true;
          break;
      }
    });
  };
}
