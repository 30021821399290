import dayjs from "dayjs";
import { ISOString } from "@sizdevteam1/funjoiner-uikit/types";

const formatStartEndDate = (entity: {
  start_date: ISOString;
  end_date: ISOString;
}) => {
  const start = dayjs(entity.start_date);
  const end = dayjs(entity.end_date);
  if (start.isSame(end)) {
    return dayjs(start).format("MMM Do");
  }
  const isSameMonth = start.month() === end.month();
  return (
    start.format("MMM Do") + " - " + end.format(isSameMonth ? "Do" : "MMM Do")
  );
};

export default formatStartEndDate;
