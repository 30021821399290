import RootStore from "../stores/RootStore";
import { action, makeObservable, observable, runInAction } from "mobx";
import {
  loadStripe,
  PaymentRequest,
  Stripe,
  StripeCardElement,
  StripeCardNumberElement,
} from "@stripe/stripe-js";
import { STRIPE_PK } from "../config";
import api, { ICardDTO, IOrderDTO } from "src/services/api";
import cardFromStripePaymentMethod from "../util/cardFromStripePaymentMethod";
import { IScheduleAndPayOrderDTO } from "../services/api/orders";

export default class PaymentStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  private stripePromiseResolver: (v: Stripe | null) => void = () => {};

  stripePromise = new Promise<Stripe | null>(
    (resolve) => (this.stripePromiseResolver = resolve),
  );

  @observable
  completedOrder: {
    order: IOrderDTO | IScheduleAndPayOrderDTO;
    card: ICardDTO | undefined;
  } | null = null;

  @action
  saveCompletedOrder(order: IOrderDTO, card: ICardDTO | undefined) {
    this.completedOrder = { order, card };
  }

  @observable
  initialized = false;

  createPaymentMethod = async (
    elementsCard: StripeCardElement | StripeCardNumberElement,
  ) => {
    const stripe = await this.stripePromise;
    if (!stripe) throw new Error("Stripe not initialized");
    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elementsCard,
    });

    if (!result.paymentMethod) {
      console.error("[error]", result.error);
      throw new Error(result.error.message);
    }

    return cardFromStripePaymentMethod(result.paymentMethod);
  };

  createPaymentRequest = async (
    description: string,
    amount: number,
    pending: boolean,
  ): Promise<PaymentRequest> => {
    const stripe = await this.stripePromise;
    if (!stripe) throw new Error("Stripe not initialized");
    return stripe.paymentRequest({
      country: "US",
      currency: "usd",
      total: {
        label: description,
        amount: amount,
        pending: pending,
      },
      disableWallets: ["googlePay", "browserCard", "link"],
    });
  };

  @action.bound
  async confirmSetupIntent(clientSecret: string, paymentMethodId: string) {
    const stripe = await this.stripePromise;
    if (!stripe) throw new Error("Stripe not initialized");
    const { error } = await stripe.confirmCardSetup(clientSecret, {
      payment_method: paymentMethodId,
    });
    if (error) {
      throw new Error(error.message);
    }
  }

  get paymentDelegate() {
    return {
      attachPaymentToInstallment: api.orders.attachPaymentToInstallment,
      getOrderById: api.orders.getOrderById,
      confirmPayment: async (
        paymentMethodId: string,
        clientSecret: string,
        savePaymentMethod: boolean = true,
      ) => {
        const stripe = await this.stripePromise;
        if (!stripe) throw new Error("Stripe not initialized");
        const { error } = await stripe.confirmCardPayment(clientSecret, {
          payment_method: paymentMethodId,
          setup_future_usage: savePaymentMethod ? "off_session" : undefined,
        });
        return error;
      },
    };
  }
  @action
  init = async () => {
    const stripeAccount = await api.integrations.stripeAccount();
    this.stripePromiseResolver(
      stripeAccount == null
        ? null
        : stripeAccount.type === "connect"
          ? await loadStripe(STRIPE_PK, {
              stripeAccount: stripeAccount.account_stripe_id,
            })
          : await loadStripe(stripeAccount.public_key),
    );

    runInAction(() => {
      this.initialized = true;
    });
  };
}
