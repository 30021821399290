/* eslint-disable */
// @ts-nocheck
import { ICustomerDTO } from "./api";

export function setupPendo(apiKey) {
  (function (p, e, n, d, o) {
    var v, w, x, y, z;
    o = p[d] = p[d] || {};
    o._q = o._q || [];
    v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
    for (w = 0, x = v.length; w < x; ++w)
      (function (m) {
        o[m] =
          o[m] ||
          function () {
            o._q[m === v[0] ? "unshift" : "push"](
              [m].concat([].slice.call(arguments, 0)),
            );
          };
      })(v[w]);
    y = e.createElement(n);
    y.async = !0;
    y.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js";
    z = e.getElementsByTagName(n)[0];
    z.parentNode.insertBefore(y, z);
  })(window, document, "script", "pendo");
}

const whitelistedPendoCompanies = ["rsa-gym"];
export function initPendo(customer: ICustomerDTO, company_id: string) {
  if (!window.pendo || !whitelistedPendoCompanies.includes(company_id)) {
    return;
  }

  window.pendo.initialize({
    visitor: {
      id: "cus-" + customer.id,
      phone: customer.phone_number,
      email: customer.email,
      first_name: customer.first_name,
      last_name: customer.last_name,
      created_at: customer.created_at,
    },
    account: {
      id: company_id,
      name: company_id,
    },
  });
}
