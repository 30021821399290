import useStores from "src/hooks/useStores";
import CreditRowWithQuantityEnd from "./CreditRowQuantityEnd";

const UpgradeCreditRowWithQuantityEnd = ({
  creditId,
  quantity,
  className,
}: {
  creditId: number;
  quantity: number;
  className?: string;
}) => {
  const { commonStore } = useStores();
  const credit = commonStore.creditTypesById[creditId];
  return (
    <CreditRowWithQuantityEnd
      className={className}
      name={"Upgrade To " + credit.name}
      isFree={credit.is_free}
      isProgram={credit.is_program_credit}
      quantity={quantity}
    />
  );
};

export default UpgradeCreditRowWithQuantityEnd;
