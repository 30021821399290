import apiService from "./apiService";
import { ICreditTypeDTO, IStudentDTO } from ".";
import { TAlternativePaymentSource } from "./orders";
import { Cents, ISOString } from "@sizdevteam1/funjoiner-uikit/types";

export type TPaymentType = "stripe_invoice" | "stripe" | "other";
export type TPaymentStatus = "incomplete" | "completed" | "cancelled";

export interface IEmergencyContactDTO {
  name: string;
  phone_number: string;
  email?: string;
}

export interface ICustomerDTO {
  id: number;
  first_name: string;
  last_name: string;
  full_name: string;
  name: string;
  phone_number: string | null;
  is_phone_number_communication_opted_in: boolean | null;
  email: string | null;
  address?: string;
  last_active?: string;
  created_at: ISOString;
  emergency_contacts: IEmergencyContactDTO[];
}

export interface ICustomerWithStudentsDTO extends ICustomerDTO {
  students: Omit<IStudentDTO, "customer">[];
}

export interface IUpdateCustomerDTO {
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  email?: string;
  address?: string;
  emergency_contacts?: IEmergencyContactDTO[];
}

export interface IRefundDTO {
  id: number;
  payment_id: number;
  amount: number;
  reason: string;
  created_by?: number;
  created_at: string;
  stripe_id: string;
}

export interface IPaymentDTO {
  id: number;
  customer_id: number;
  payment_type: TPaymentType;
  status: TPaymentStatus;
  completed_at: string;
  stripe_client_secret?: string;
  stripe_intent_id?: string;
  stripe_invoice_id?: string;
  amount: number;
  description: string;
  refunds: IRefundDTO[];
  dispute_id: string;
  dispute: string;
  created_at: ISOString;
  alternative_payment_source?: TAlternativePaymentSource;
}

export interface ICreditDTO {
  id: number;
  customer_id: number;
  credit_type_id: number;
  created_at: ISOString;
  can_be_upgraded: boolean;
  redeemed: boolean;
  redeemed_for: {
    student_id: number;
    attendance_id: string;
    start_date: ISOString;
    end_date: ISOString;
    schedule_set_id: string;
  } | null;
  credit_type: ICreditTypeDTO;
  created_by: number;

  expired: boolean;
  expires_at?: ISOString;

  deleted: boolean;
  delete_reason: string;
  deleted_at: ISOString;
  deleted_by: number;

  unpaid_payment_plan: null | {
    ignore_restriction_date_for_reschedule: boolean;
    last_payment_date: ISOString;
    reschedule_after_date: ISOString | undefined;
    missed_payments: {
      ordinal: number;
      amount: number;
      payment_date: ISOString;
    }[];
  };
}

export interface IPromocodeDTO {
  id: string;
  status: "active" | "inactive" | "archived" | "expired" | "used";
  discount_percent: number;
  maximum_discount_value: Cents;
  expiration_date: ISOString;
  max_usages: number;
  usages_left: number;
  credit_type_ids: number[] | null;
  base_price_only?: boolean;
}

export const get = (): Promise<ICustomerWithStudentsDTO> =>
  apiService.get("/customer_api/v2/profile/");

export const update = (dto: IUpdateCustomerDTO): Promise<ICustomerDTO> =>
  apiService.put("/customer_api/v2/profile/", { data: dto });

export const setCommunicationPreferences = (dto: {
  is_phone_number_communication_opted_in: boolean;
}): Promise<ICustomerDTO> =>
  apiService.post("/customer_api/v2/profile/set_communication_preferences", {
    data: dto,
  });

export const changePhoneWithFirebaseToken = (token: string) =>
  apiService.post<ICustomerDTO>(
    "/customer_api/v2/auth/change_phone_with_firebase_token",
    {
      data: { token },
    }
  );
export const changeEmailWithEmailCode = (code_id: string, code: string) =>
  apiService.post<ICustomerDTO>(
    "/customer_api/v2/auth/change_email_with_email_code",
    {
      data: { code_id, code },
    }
  );
export const credits = (): Promise<ICreditDTO[]> =>
  apiService.get("/customer_api/v2/profile/credits");

export const receipt = (orderId: number): Promise<Response> =>
  apiService.get(`/customer_api/v2/profile/receipt/${orderId}`, {
    rawResponse: true,
  });

export const reportSmsAbuse = (text: string) =>
  apiService.post("/customer_api/v2/profile/report_sms_abuse", {
    data: { text },
  });
