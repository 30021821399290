import { ILocationDTO } from "src/services/api";
import { ILocationFolderDTO } from "src/services/api/common";

const getUniqueFoldersFromLocations = (
  locationsArray: ILocationDTO[]
): ILocationFolderDTO[] => {
  const uniqueFoldersMap = new Map<string, ILocationFolderDTO>();

  locationsArray.forEach((location) => {
    const folder = location.folder;

    if (folder && !uniqueFoldersMap.has(folder.id)) {
      uniqueFoldersMap.set(folder.id, folder);
    }
  });

  return Array.from(uniqueFoldersMap.values());
};

export default getUniqueFoldersFromLocations;
