import apiService from "./apiService";
import { IStorageFileDTO } from "./files";
import { ISOString } from "@sizdevteam1/funjoiner-uikit/types";
import { ILocationDTO, IPagination, IPaginationParams } from "./common";
import { IStudentDTO } from "./students";

interface IEmployeeDTO {
  id: number;
  first_name: string;
  last_name: string;
  full_name: string;
  name: string;
  phone_number?: string;
  email: string | undefined;
  photo?: string;
  photo_file?: IStorageFileDTO;
  photo_key?: string;
  thumbnail?: string;
  thumbnail_key?: string;
  deactivated: boolean;
  created_at: ISOString;
}
type TMedicalNote = {
  id: string;
  text: string;
  created_at: ISOString;
  employee: IEmployeeDTO;
  attachments: IStorageFileDTO[];
};

type MedicationError = {
  reported_by: IEmployeeDTO;
  reported_at: ISOString;
  text: string;
};
interface IMedicationRecordDTOBase {
  id: string;
  is_shown_in_customer_hub: boolean;
  student: IStudentDTO;
  administrated_datetime: ISOString;
  administrated_by: IEmployeeDTO;
  location: ILocationDTO;
  medication_name: string;
  strength_dosage: string;
  created_at: ISOString;
  created_by: IEmployeeDTO;
  user_error: MedicationError | null;
  treatment_error: MedicationError | null;
  attachments?: IStorageFileDTO[];
  description?: string;
  additional_notes: TMedicalNote[];
}

interface IRegularMedicationRecordDTO extends IMedicationRecordDTOBase {
  type: "regular";
  note?: string;
}

interface IIrregularMedicationRecordDTO extends IMedicationRecordDTOBase {
  type: "irregular";
  reason: string;
}
export type MedicationRecordDTO =
  | IRegularMedicationRecordDTO
  | IIrregularMedicationRecordDTO;

export type IncidentType =
  | "accident"
  | "behavioural"
  | "epidemic_illness"
  | "other";

export type MedicalError = {
  reported_by: IEmployeeDTO;
  reported_at: ISOString;
  text: string;
};

export interface IncidentReportDTO {
  id: string;
  is_shown_in_customer_hub: boolean;
  student: IStudentDTO;
  incident_date: ISOString;
  location: ILocationDTO;
  administrated_by?: IEmployeeDTO;
  treatment_medication_given?: string;
  treatment_datetime?: ISOString;
  created_at: ISOString;
  created_by: IEmployeeDTO;
  type: IncidentType;
  other_type_description: string | null;
  sequence_of_activity: string;
  where_occurred: string;
  injured_during_activity: string | null;
  equipment_involved: string | null;
  full_names_of_responders: string;
  witness_info: string | null;
  emergency_procedures: string;
  additional_notes: TMedicalNote[];
  attachments: IStorageFileDTO[];
  prevent_injury?: string;
  feedback?: string;
  note?: string;
  user_error: MedicalError | null;
}

export const isMedicationRecord = (
  item: IncidentReportDTO | MedicationRecordDTO
): item is MedicationRecordDTO => {
  return item.type === "regular" || item.type === "irregular";
};

export const getStudentHealthLog = async (
  studentId: number,
  params: IPaginationParams
) =>
  apiService.get<IPagination<IncidentReportDTO | MedicationRecordDTO>>(
    `/customer_api/v2/health_log/student/${studentId}`,
    { searchParams: params }
  );
export const hasAnyStudentVisibleHealthLogRecords = () => {
  return apiService.get<boolean>(
    "/customer_api/v2/health_log/has_any_student_visible_health_log_records"
  );
};

export const medicationRecordPdf = (
  medicationRecordId: string
): Promise<Response> =>
  apiService.get(
    `/customer_api/v2/health_log/medication_records/${medicationRecordId}/download_pdf`,
    {
      rawResponse: true,
    }
  );
export const incidentReportPdf = (incidentReport: string): Promise<Response> =>
  apiService.get(
    `/customer_api/v2/health_log/incident_reports/${incidentReport}/download_pdf`,
    {
      rawResponse: true,
    }
  );
