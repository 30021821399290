import React from "react";

export interface IFlexContainerProps
  extends React.ButtonHTMLAttributes<HTMLDivElement> {
  padding?: string;
  flexDirection?: "row" | "column";
  justifyContent?:
    | "start"
    | "end"
    | "space-around"
    | "space-between"
    | "center"
    | "flex-end";
  alignItems?: "start" | "end" | "center" | "inherit" | "unset" | "flex-end";
  crossAxisSize?: "min" | "max";
}

const FlexContainer: React.FC<IFlexContainerProps> = ({
  children,
  padding,
  flexDirection,
  justifyContent,
  alignItems,
  crossAxisSize,
  style,
  ...props
}) => (
  <div
    style={{
      [flexDirection === "column" ? "height" : "width"]: "100%",
      [flexDirection === "column" ? "width" : "height"]:
        crossAxisSize === "max" ? "100%" : null,
      display: "flex",
      flexDirection: flexDirection ?? "row",
      justifyContent: justifyContent ?? "start",
      alignItems: alignItems ?? "start",
      padding: padding,
      ...style,
    }}
    {...props}
  >
    {children}
  </div>
);

export default FlexContainer;
