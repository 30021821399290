import React from "react";
import { BrowserHistory, createBrowserHistory } from "@remix-run/router";
import { BrowserRouterProps, Router } from "react-router-dom";

const historyCtx = React.createContext<BrowserHistory | null>(null);
export const useHistory = () => {
  const history = React.useContext(historyCtx);
  if (!history) {
    throw new Error("useHistory must be used within a Router");
  }
  return history;
};
export function BrowserRouter({
  basename,
  children,
  future,
  window,
}: BrowserRouterProps) {
  const historyRef = React.useRef<BrowserHistory>();
  if (historyRef.current == null) {
    historyRef.current = createBrowserHistory({ window, v5Compat: true });
  }

  const history = historyRef.current;
  const [state, setState] = React.useState({
    action: history.action,
    location: history.location,
  });

  React.useLayoutEffect(() => history.listen(setState), [history, setState]);

  return (
    <historyCtx.Provider value={history}>
      <Router
        basename={basename}
        children={children}
        location={state.location}
        navigationType={state.action}
        navigator={history}
        future={future}
      />
    </historyCtx.Provider>
  );
}
