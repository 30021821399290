import {
  Button,
  FlipCard,
  Image,
  MarkdownText,
} from "@sizdevteam1/funjoiner-uikit";
import cn from "classnames";
import React, { useState } from "react";
import { IFunboxDTO } from "src/services/api";
import BackLink from "../BackLink";
import FunboxPlaceholderImage from "../FunboxPlaceholderImage";
import styles from "./FunboxCard.module.scss";

const FunboxCard = ({
  funbox,
  onSelect,
  isSelected,
}: {
  funbox: IFunboxDTO;
  onSelect: (funbox: IFunboxDTO) => void;
  isSelected: boolean;
}) => {
  const [isShowingDescription, setIsShowingDescription] = useState(false);

  return (
    <FlipCard
      isFlipped={isShowingDescription}
      front={
        <div
          className={cn(
            styles.flipCardFront,
            "cursor-pointer",
            isSelected && styles.selected,
          )}
          onClick={onSelect.bind(null, funbox)}
        >
          <div className={"flex gap-4"}>
            {funbox.thumbnail ? (
              <Image
                src={funbox.thumbnail}
                className={"!h-[120px] !w-[120px] shrink-0 !rounded-[8px]"}
              />
            ) : (
              <FunboxPlaceholderImage />
            )}

            <div>
              <div
                className={cn(
                  "typography-h2 w-[198px] overflow-hidden text-ellipsis whitespace-nowrap pb-3 text-header-color",
                )}
              >
                {funbox.name}
              </div>
              <div
                className={
                  "typography-small__t h-[60px] w-[198px] overflow-hidden whitespace-pre-line break-words text-gray-text-color"
                }
              >
                {funbox.requirements}
              </div>
              {funbox.description && (
                <Button
                  className={"!typography-label mt-2"}
                  kind={"text"}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsShowingDescription(true);
                  }}
                >
                  See Description
                </Button>
              )}
            </div>
          </div>
        </div>
      }
      renderBack={(style) => (
        <div
          style={style}
          className={cn(styles.flipCardBack, isSelected && styles.selected)}
        >
          <BackLink
            className={"!mb-0"}
            onClick={() => setIsShowingDescription(false)}
          />
          <MarkdownText
            className={
              "typography-small__t mt-2 mb-[10px] text-gray-text-color"
            }
          >
            {funbox.description}
          </MarkdownText>
          <Button
            className={"!typography-label mt-auto"}
            kind={"text"}
            onClick={onSelect.bind(null, funbox)}
          >
            Select This Option
          </Button>
        </div>
      )}
    />
  );
};

export default FunboxCard;
