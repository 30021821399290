import { defineRoute } from "../../util/typedRouting";
import { TAttendanceDTO } from "../../services/api";

export const rescheduleRoute = defineRoute<{
  state: {
    attendance: TAttendanceDTO;
  };
}>({
  path: "/reschedule",
  build: (path) => path,
});
