import { Navigate, useLocation } from "react-router-dom";
import PublicRouteContent from "src/components/PublicRouteContent";
import useStores from "../hooks/useStores";
import React from "react";
import { pathFromLocation, ROUTES } from "../stores/RouterStore";
import { Splash } from "@sizdevteam1/funjoiner-uikit";
import { observer } from "mobx-react-lite";
import { ImmediateRedirect } from "./ImmediateRedirect";

interface IProps {
  title?: string;
  children: React.ReactNode;
}

const PrivateRouteContent: React.FC<IProps> = observer(
  ({ title, children }) => {
    const { authStore, routerStore } = useStores();
    const customerEmail = routerStore.currentSearch.get("customer_email");
    const location = useLocation();

    return (
      <PublicRouteContent title={title}>
        {!authStore.initialized ? (
          <Splash pathToAnimation={"/splash.json"} />
        ) : authStore.loggedIn ? (
          children
        ) : (
          <ImmediateRedirect
            to={{
              pathname: ROUTES.SIGN_IN,

              search:
                location.search +
                (customerEmail ? "&customer_email=" + customerEmail : ""),
            }}
            state={{
              fromState: location.state,
              from: pathFromLocation(location),
            }}
            replace={true}
          />
        )}
      </PublicRouteContent>
    );
  },
);
export default PrivateRouteContent;
