import React from "react";
import styles from "./PageBlock.module.scss";
import cn from "classnames";

interface IProps extends React.HTMLProps<HTMLDivElement> {}

const PageBlock = React.forwardRef<HTMLDivElement, IProps>(
  ({ children, className, ...rest }, ref) => {
    return (
      <div className={cn(styles.block, className)} {...rest} ref={ref}>
        {children}
      </div>
    );
  }
);

export default PageBlock;
