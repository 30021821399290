import { action, makeObservable } from "mobx";
import api from "src/services/api";
import { SetAnswerDTO } from "@sizdevteam1/funjoiner-web-shared/components/QuestionPipeline/QuestionPipelineVm";

export class QuestionSetStore {
  constructor(private onQuestionSetUpdated: () => void) {
    makeObservable(this);
  }

  @action.bound
  async submitQuestionSet(
    smartFormTemplateId: string,
    studentId: number,
    answers: SetAnswerDTO[],
    virtual_signature: string | null
  ): Promise<void> {
    await api.questionSets.setAnswers({
      smart_form_template_id: smartFormTemplateId,
      student_id: studentId,
      answers: answers,
      virtual_signature,
    });
    this.onQuestionSetUpdated();
  }

  @action.bound
  async skipQuestionSet(
    questionSetId: string,
    studentId: number
  ): Promise<void> {
    await api.questionSets.skip(questionSetId, studentId);
    this.onQuestionSetUpdated();
  }
}
