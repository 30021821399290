import { defineRoute } from "../../util/typedRouting";

export const applicationPageRoute = defineRoute<{
  pathParams: {
    application_id: string;
  };
  searchParams?: {
    document_id?: string;
  };
}>({
  path: "/applications/:application_id",
  build: (path, { application_id }) => {
    return path.replace(":application_id", application_id);
  },
});
