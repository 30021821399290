import React from "react";

export default function useVM<T>(storeContext: React.Context<T | null>) {
  const store = React.useContext(storeContext);
  if (!store) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error("useVM must be used within a VMProvider.");
  }
  return store;
}
