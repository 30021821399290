import dayjs from "dayjs";
import { ISOString } from "@sizdevteam1/funjoiner-uikit/types";

const formatStartEndTime = (item: {
  date: ISOString;
  start_time: string;
  end_time: string;
}) =>
  dayjs(item.date + "T" + item.start_time).format("h:mmA") +
  " - " +
  dayjs(item.date + "T" + item.end_time).format("h:mmA");

export default formatStartEndTime;
