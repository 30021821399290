import React from "react";
import { observer } from "mobx-react-lite";
import useStores from "src/hooks/useStores";
import { ROUTES } from "src/stores/RouterStore";
import ContactsAndTerms from "./ContactsAndTerms";
import { matchPath } from "react-router-dom";
import { applicationProcessPageRoute } from "../pages/ApplicationProcessPage/applicationProcessPageRoute";

const Footer = observer(() => {
  const ref = React.useRef<HTMLDivElement>(null);
  const { routerStore } = useStores();
  const page = routerStore.currentPath;

  const footerHiddenOnRoutes = [
    ROUTES.SCHEDULE,
    ROUTES.AVAILABILITY,
    ROUTES.ADD_PARTICIPANT,
    ROUTES.EDIT_PARTICIPANT,
    ROUTES.PAYMENT_PLAN,
    ROUTES.FLEXIBLE_PAYMENTS_CHECKOUT,
    ROUTES.UPGRADE_CREDIT_CHECKOUT,
    ROUTES.SCHEDULE_CHECKOUT,
    ROUTES.PAYMENT,
    ROUTES.RESCHEDULE,
    ROUTES.CANCEL_CREDIT,
    ROUTES.LOCATIONS,
    ROUTES.PARTICIPANTS,

    applicationProcessPageRoute.path,
  ];

  const matchPathWithRoutes = (path: string, routes: string[]) => {
    for (let i = 0; i < routes.length; i++) {
      const route = routes[i];
      const match = matchPath(
        {
          path: route,
          end: false,
        },
        path,
      );
      if (match) {
        return {
          match,
        };
      }
    }
    return null;
  };

  const showContactsAndTerms = !matchPathWithRoutes(page, footerHiddenOnRoutes);

  const hideContacts = page === ROUTES.HELP;

  React.useEffect(() => {
    if (
      ref.current &&
      document.body.scrollHeight <= window.innerHeight &&
      page !== "/"
    ) {
      ref.current.scrollIntoView();
    }
  }, [page]);

  return (
    <div>
      <div ref={ref} />
      {showContactsAndTerms && <ContactsAndTerms hideContacts={hideContacts} />}
    </div>
  );
});

export default Footer;
