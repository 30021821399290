import React, { useEffect, useState } from "react";
import cn from "classnames";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import FlexContainer from "src/components/FlexContainer";
import { Button, Checkbox, Input, Label } from "@sizdevteam1/funjoiner-uikit";
import styles from "./NewCard.module.scss";

const style = {
  base: {
    fontFamily: "Poppins,sans-serif",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "20px",
    color: "#424242",
  },
};

export type RememberCardProps = {
  checked: boolean;
  onChange: (v: boolean) => void;
};
interface IProps {
  onClose?: () => void;
  rememberCard?: RememberCardProps;
  onAddCard?: () => void;
  className?: string;
  onValidityChanged?: (isValid: boolean) => void;
}
const NewCard = ({
  onClose,
  rememberCard,
  onAddCard,
  className,
  onValidityChanged,
}: IProps) => {
  const [isNumberValid, setIsNumberValid] = useState(false);
  const [isExpirationValid, setIsExpirationValid] = useState(false);
  const [isCvcValid, setIsCvcValid] = useState(false);

  useEffect(() => {
    onValidityChanged?.(isNumberValid && isExpirationValid && isCvcValid);
  }, [isNumberValid, isExpirationValid, isCvcValid, onValidityChanged]);

  const isValid = isNumberValid && isExpirationValid && isCvcValid;

  return (
    <div
      className={cn(styles.cardSection, className)}
      data-cy="new-card-container"
    >
      <div className={"flex items-center justify-between"}>
        <div className={"text-main_sb text-text-color"}>New card</div>
        {onClose && (
          <Button kind="icon" onClick={onClose}>
            <i
              className={"icon close-icon cursor-pointer !bg-icon-grey-color"}
            />
          </Button>
        )}
      </div>
      <div className={"space-y-6 pb-2"}>
        <div data-cy="card-number-wrapper">
          <Label>Card Number</Label>
          <CardNumberElement
            className={cn(styles.input)}
            onChange={(e) => setIsNumberValid(e.complete)}
            options={{
              placeholder: "0000 0000 0000 0000",
              showIcon: false,
              style,
            }}
          />
        </div>
        <FlexContainer
          className={cn(styles.exp)}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <div data-cy="card-expiration-wrapper">
            <Label>Expiration Date</Label>
            <CardExpiryElement
              className={styles.input}
              onChange={(e) => setIsExpirationValid(e.complete)}
              options={{
                placeholder: "00/00",
                style,
              }}
            />
          </div>
          <div data-cy="card-cvc-wrapper">
            <Label>CVV</Label>
            <CardCvcElement
              className={styles.input}
              onChange={(e) => setIsCvcValid(e.complete)}
              options={{
                placeholder: "000",
                style,
              }}
            />
          </div>
        </FlexContainer>
        {rememberCard && (
          <Checkbox {...rememberCard}>Remember card for next purchase</Checkbox>
        )}
        {onAddCard && (
          <Button
            disabled={!isValid}
            size={"big"}
            className="w-full"
            onClick={onAddCard}
            autoLoading
            data-cy="add-card-btn"
          >
            Add Card
          </Button>
        )}
      </div>
    </div>
  );
};

interface IEmailInputProps {
  value: string;
  onChange: (v: string) => void;
  error?: string | null;
  className?: string;
}
export const EmailInput = ({
  value,
  onChange,
  error,
  className,
}: IEmailInputProps) => (
  <div className={className}>
    <Label>Email</Label>
    <Input
      placeholder={"customer@example.com"}
      value={value}
      isError={error != null}
      errorText={error}
      onChange={(e) => onChange(e.currentTarget.value)}
    />
  </div>
);

export default NewCard;
