import { TAvailableProgram } from "../../../services/api/availability";
import { defineRoute } from "../../../util/typedRouting";

interface IJoinWaitlistPageArgs {
  program: TAvailableProgram;
  selectedParticipantIds: number[];
}

export const joinWaitlistRoute = defineRoute<{
  state: {
    args: IJoinWaitlistPageArgs;
  };
}>({
  path: "/schedule/waitlist",
  build: (path) => path,
});
