import { ApplicationDTO, ApplicationDrafts } from "./availability";
import apiService from "./apiService";
import { ICalculatedScheduleAndPayOrderDTO } from "./orders";

export const getApplications = async () => {
  return apiService.get<ApplicationDTO[]>("/customer_api/v2/applications/");
};

export const submitApplications = async (data: {
  application_ids: string[];
  ignore_requires_payment_information?: boolean;
}) => {
  return apiService.post<void>("/customer_api/v2/applications/submit", {
    data,
  });
};

export const calculateOrder = async (data: {
  application_ids: string[];
  use_existing_credits: boolean;
}) => {
  return apiService.post<ICalculatedScheduleAndPayOrderDTO>(
    `/customer_api/v2/applications/calculate_order`,
    {
      data: data,
    }
  );
};
export const withdrawApplication = async (application_id: string) => {
  return apiService.delete<void>(
    `/customer_api/v2/applications/${application_id}`
  );
};

export const archiveApplication = async (application_id: string) => {
  return apiService.post<void>(
    `/customer_api/v2/applications/${application_id}/acknowledge_rejection`
  );
};
export const getOrCreateApplications = async (
  schedule_set_id: string,
  program_id: string,
  student_ids: number[]
) => {
  return apiService.post<ApplicationDrafts>(
    "/customer_api/v2/applications/get_or_create_drafts_for_program",
    {
      data: {
        schedule_set_id,
        program_id,
        student_ids,
      },
    }
  );
};
