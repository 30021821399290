import React from "react";
import { twMerge } from "tailwind-merge";

const FunboxPlaceholderImage = ({
  containerClassName,
  iconClassName,
}: {
  containerClassName?: string;
  iconClassName?: string;
}) => {
  return (
    <div
      className={twMerge(
        "box-border flex h-[120px] w-[120px] items-center justify-center rounded-[8px] border-[1px] border-solid border-separator-color bg-gray-surface-color",
        containerClassName
      )}
    >
      <i
        className={twMerge(
          "icon funbox-icon  h-[72px] w-[72px]",
          iconClassName
        )}
      />
    </div>
  );
};

export default FunboxPlaceholderImage;
