import { defineRoute } from "../../util/typedRouting";

export const applicationsAndWaitlistsPageRoute = defineRoute<{
  searchParams?: {
    studentId?: string;
  };
}>({
  path: "/applications/",
  build: (path) => path,
});
