import React, { useEffect } from "react";
import cn from "classnames";
import styles from "./styles.module.scss";

interface IProps extends React.HTMLProps<HTMLDivElement> {}

const PageRoot: React.FC<IProps> = ({ children, ...rest }) => {
  return (
    <div
      className={cn(styles.PageRoot, rest.className)}
      style={rest.style}
      data-cy="page-root"
    >
      <ScrollToTopOnMount />
      {children}
    </div>
  );
};

export default PageRoot;

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}
