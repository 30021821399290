import React from "react";
import cn from "classnames";
import styles from "./PageHeader.module.scss";
import BackLink from "../BackLink";
import { ROUTES } from "src/stores/RouterStore";

interface IProps extends React.HTMLProps<HTMLDivElement> {
  showBackLink?: boolean;
  onBackLinkClick?: () => void;
  backLinkRoute?: (typeof ROUTES)[keyof typeof ROUTES];
  backLinkOptions?: {
    id?: number | string;
    replace?: boolean;
    searchParams?: Record<string, string | null>;
  };
}

const PageHeader: React.FC<IProps> = ({
  showBackLink,
  onBackLinkClick,
  backLinkRoute,
  backLinkOptions,
  children,
  className,
  ...rest
}) => (
  <div
    data-cy="page-header"
    className={cn("text-h1", styles.header, className)}
    {...rest}
  >
    {showBackLink && (
      <BackLink
        route={backLinkRoute}
        options={backLinkOptions}
        onClick={onBackLinkClick}
        className={styles.backLink}
      />
    )}
    {children}
  </div>
);

export default PageHeader;
