import React, { useState } from "react";
import cn from "classnames";
import Attention from "src/assets/imgs/Attention.svg";
import styles from "./AttentionMessage.module.scss";
import { Button, MarkdownText } from "@sizdevteam1/funjoiner-uikit";
interface IMessage {
  text: string;
  enabled: boolean;
  color: string;
  onClose?: () => void;
  visibleCondition?: boolean;
}

const AttentionMessage: React.FC<IMessage> = ({
  text,
  enabled,
  color,
  onClose,
  visibleCondition,
}) => {
  const [isVisible, setIsVisible] = useState(visibleCondition ?? true);

  if (!enabled) return null;

  return (
    <div
      className={cn(
        styles.attention,
        "shadow-big transition-all",
        !isVisible && "!mb-0 h-0 overflow-hidden"
      )}
    >
      <div style={{ backgroundColor: color }}>
        <img src={Attention} alt={"Attention icon"} />
      </div>
      <div className={cn("py-4 pl-4 pr-[34px]")}>
        <MarkdownText
          className={cn(
            "typography-small__t w-[258px] break-words text-text-color"
          )}
        >
          {text}
        </MarkdownText>
      </div>
      {onClose && (
        <Button
          onClick={() => {
            setIsVisible(false);
            onClose();
          }}
          className="absolute right-[10px] top-[10px]"
          kind="icon"
        >
          <i className="icon cross-icon !bg-icon-grey-color" />
        </Button>
      )}
    </div>
  );
};

export default AttentionMessage;
