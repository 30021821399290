import { defineRoute } from "../../util/typedRouting";
import { SmartFormDTO } from "../../services/api/smartForms";

export const smartFormPipelinePageRoute = defineRoute<{
  state: {
    description?: string;
    smartForms: SmartFormDTO[];
  };
}>({
  path: "/application-smart-forms/",
  build: (path) => path,
});
