import React from "react";
import cn from "classnames";
import styles from "./FunboxMenu.module.scss";
import useStores from "../../hooks/useStores";
import FunboxCard from "src/components/FunboxCard/FunboxCard";
import { IFunboxDTO } from "../../services/api";
import { Button } from "@sizdevteam1/funjoiner-uikit";
import { ROUTES } from "src/stores/RouterStore";

const FunboxMenu = ({ onClose }: { onClose: () => void }) => {
  const { funboxStore, routerStore } = useStores();

  const funboxesInCurrentLocation = funboxStore.funboxes
    .filter((fb) =>
      fb.active_locations.some(
        (l) => l.id === funboxStore.selectedLocation?.id,
      ),
    )
    .sort((a, b) => {
      if (a.id === funboxStore.selectedFunbox?.id) return -1;
      if (b.id === funboxStore.selectedFunbox?.id) return 1;
      return 0;
    });

  const selectFunbox = (funbox: IFunboxDTO) => {
    funboxStore.selectFunbox(funbox);
    onClose();
  };

  const hasMoreFunboxesInDifferentLocations =
    funboxStore.funboxes.length - funboxesInCurrentLocation.length > 0;

  return (
    <>
      <div className={cn(styles.funboxMenu)}>
        <div className="mx-auto flex w-[366px] flex-col gap-4">
          {funboxStore.selectedLocation && (
            <div className="typography-main mx-auto flex w-[366px] items-center gap-1 text-gray-text-color">
              <i className="icon locations-icon mr-1" />
              <div className="max-w-[300px] overflow-hidden text-ellipsis">
                {funboxStore.selectedLocation?.name}
              </div>
            </div>
          )}
          {funboxesInCurrentLocation.map((fb) => (
            <FunboxCard
              key={fb.id}
              funbox={fb}
              onSelect={selectFunbox}
              isSelected={funboxStore.selectedFunbox?.id === fb.id}
            />
          ))}
          {hasMoreFunboxesInDifferentLocations && (
            <>
              <div className="mx-6 flex items-center justify-between gap-6">
                <div className="h-[1px] w-full bg-separator-color"></div>
                <div className="typography-h4 text-placeholder-color">or</div>
                <div className="h-[1px] w-full bg-separator-color"></div>
              </div>
              <Button
                className="mb-[14px]"
                onClick={() => {
                  onClose();
                  routerStore.navigate(ROUTES.SELECT_FUNBOX_PAGE, {
                    searchParams: {
                      selected_tab: "funbox",
                    },
                  });
                }}
                kind="text"
              >
                View More Options
              </Button>
            </>
          )}
        </div>
      </div>
      <div className={cn(styles.overlay)} onClick={onClose} />
    </>
  );
};

export default FunboxMenu;
