import React, { useState } from "react";
import dayjs from "dayjs";

import formatStartEndDate from "src/util/formatStartEndDate";
import {
  DateTimeObject,
  getDaysWithSessions,
  getMonthsWithSessions,
  getWeeksWithSessions,
} from "src/util/groupSessions";
import { SessionsDisplayOption } from "src/services/api/availability";
import SessionRow from "../SessionRow/SessionRow";
import classNames from "classnames";
import { ExpandableRow } from "@sizdevteam1/funjoiner-uikit";

export const GroupedSessions = <T extends DateTimeObject>({
  sessions,
  displayOption,
}: {
  sessions: T[];
  displayOption: SessionsDisplayOption;
}) => {
  const rows =
    displayOption === "MONTH"
      ? getMonthsWithSessions(sessions)
      : displayOption === "WEEK"
      ? getWeeksWithSessions(sessions)
      : displayOption === "DAY"
      ? getDaysWithSessions(sessions)
      : [];
  return (
    <div>
      {rows.map((r, index) => (
        <div
          key={index}
          className={classNames("px-4 py-3", getBgRowColorByIndex(index))}
        >
          <ExpandableDateRow
            dateStringNode={
              "startOfMonth" in r ? (
                <div className="typography-main_sb text-dark-main-color">
                  {dayjs(r.startOfMonth).format("MMM, YYYY")}
                </div>
              ) : "startOfWeek" in r && "endOfWeek" in r ? (
                <div className="typography-main_sb text-dark-main-color">
                  {formatStartEndDate({
                    start_date: r.startOfWeek.toISOString(),
                    end_date: r.endOfWeek.toISOString(),
                  })}{" "}
                </div>
              ) : "day" in r ? (
                <div className="typography-main_sb text-dark-main-color">
                  {r.day.format("MMM Do")}{" "}
                </div>
              ) : null
            }
            expand={
              <div
                className={classNames(
                  "flex flex-col pt-2",
                  getBgRowColorByIndex(index)
                )}
              >
                {r.sessions.map((s, index) => (
                  <SessionRow key={index} session={s} />
                ))}
              </div>
            }
          />
        </div>
      ))}
    </div>
  );
};

export const ExpandableDateRow = ({
  dateStringNode,
  expand,
  className,
}: {
  dateStringNode: React.ReactNode;
  expand: React.ReactNode;
  className?: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <ExpandableRow
      className={classNames("!w-full")}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      children={
        <div
          className={classNames(
            "typography-small__t  flex cursor-pointer items-center justify-between  gap-2",
            className
          )}
        >
          {dateStringNode}
          <i
            className={classNames(
              "icon chevron-down-icon icon-md transition-all",
              isOpen && "rotate-180"
            )}
          />
        </div>
      }
      expand={expand}
    />
  );
};

export const getBgRowColorByIndex = (index: number) => {
  return index % 2 === 0 ? "bg-table-row-color" : "bg-on-main-color";
};
