import { SmartFormDTO } from "src/services/api/smartForms";

export function startIndexForQuestionSet(set: SmartFormDTO) {
  const index = set.questions.findIndex((e) => e.answer != null);
  return index === -1 ? 0 : index;
}

export class SmartFormUtils {
  static filterIncomplete = (forms: SmartFormDTO[]) => {
    return forms.filter((form) => form.status === "INCOMPLETE");
  };
  static filterIncompleteRequired = (forms: SmartFormDTO[]) => {
    return forms.filter(
      (form) => form.status === "INCOMPLETE" && form.is_required
    );
  };
  static filterIncompleteOptional = (forms: SmartFormDTO[]) => {
    return forms.filter(
      (form) => form.status === "INCOMPLETE" && !form.is_required
    );
  };
  static filterComplete = (forms: SmartFormDTO[]) => {
    return forms.filter((form) => form.status !== "INCOMPLETE");
  };
}
