import apiService from "./apiService";
import { IOrderDTO, ICustomerDTO } from ".";

export interface IInvoiceDTO extends IOrderDTO {
  customer: ICustomerDTO;
  // employee: { first_name: string; last_name: string };
}
export interface ISearchInvoicesParams {
  status: "incomplete" | "completed" | "cancelled";
}
export const get = (
  searchParams: ISearchInvoicesParams
): Promise<{ items: IInvoiceDTO[] }> =>
  apiService.get("/customer_api/v2/invoices/", {
    searchParams,
  });

export const getInvoicePaymentLink = (invoiceId: number): Promise<string> =>
  apiService.get(`/customer_api/v2/invoices/${invoiceId}/payment_link`);

export const downloadInvoicePdf = (invoice_id: number): Promise<Response> =>
  apiService.get(`/customer_api/v2/invoices/${invoice_id}/pdf`, {
    rawResponse: true,
  });
