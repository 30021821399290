import React from "react";

interface PublicRouteContentProps {
  title?: string;
  children: React.ReactNode;
}

const PublicRouteContent: React.FC<PublicRouteContentProps> = ({
  title,
  children,
}) => {
  React.useEffect(() => {
    if (title) document.title = title;
  }, [title]);

  return children;
};
export default PublicRouteContent;
