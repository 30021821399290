import apiService from "./apiService";
import { ISOString } from "@sizdevteam1/funjoiner-uikit/types";
import { IStorageFileDTO } from "./files";

export type SmartFormStatus = "COMPLETE" | "INCOMPLETE" | "AWAITING_APPROVAL";

export type SmartFormDTO = {
  id: string;
  name: string;
  student_id: number;
  template_id: string;
  is_required: boolean;
  is_archived: boolean;
  last_reminder_at: ISOString | undefined;
  is_internal_review_required: boolean;
  was_submitted_at_least_once: boolean;
  status: SmartFormStatus;
  questions: SmartFormQuestionDTO[];
  virtual_signature_settings: SmartFormVirtualSignatureSettingsDTO | undefined;
  virtual_signature: SmartFormVirtualSignatureDTO | undefined;
  resubmission_reason: string | undefined;

  location_ids: number[] | "all";
  program_type_ids: number[] | "all";
};

export type SmartFormVirtualSignatureSettingsDTO = {
  instructions: string;
  description: string;
};

export type SmartFormVirtualSignatureDTO = {
  id: string;
  signed_at: string;
  sign_value: string;
  is_expired: boolean;
  is_signed: boolean;
};

export type SmartFormQuestionBaseDTO = {
  id: string;
  smart_form_id: string;
  template_id: string;
  text: string;
  description: string;

  is_required: boolean;
  is_answer_editable_by_customer: boolean;
  is_answer_hidden_on_employee_app: boolean;
  answer_valid_for_days: number | undefined;
};

export type SmartFormQuestionAnswerBaseDTO = {
  employee_id: number | undefined;
};

export type TextQuestionInputType = "TEXT_AREA" | "INPUT";

export type TextQuestionDTO = SmartFormQuestionBaseDTO & {
  type: "TEXT";
  input_type: TextQuestionInputType;
  answer: TextQuestionAnswerDTO | undefined;
};
export type TextQuestionAnswerDTO = SmartFormQuestionAnswerBaseDTO & {
  type: "TEXT";
  value: string;
  created_at: ISOString;
};

export type SelectQuestionDTO = SmartFormQuestionBaseDTO & {
  type: "SELECT";
  options: string[];
  answer: SelectQuestionAnswerDTO | undefined;
};
export type SelectQuestionAnswerDTO = SmartFormQuestionAnswerBaseDTO & {
  type: "SELECT";
  value: string;
  created_at: ISOString;
};

export type MultiChoiceQuestionDTO = SmartFormQuestionBaseDTO & {
  type: "MULTI_CHOICE";
  options: string[];
  multiple_selection: boolean;
  has_other_option: boolean;
  answer: MultiChoiceQuestionAnswerDTO | undefined;
};

export type MultiChoiceQuestionAnswerDTO = SmartFormQuestionAnswerBaseDTO & {
  type: "MULTI_CHOICE";
  value: string[];
  created_at: ISOString;
};

export type FileUploadQuestionDTO = SmartFormQuestionBaseDTO & {
  type: "FILE_UPLOAD";
  answer: FileUploadQuestionAnswerDTO | undefined;
};

export type FileUploadQuestionAnswerDTO = SmartFormQuestionAnswerBaseDTO & {
  type: "FILE_UPLOAD";

  value: IStorageFileDTO[];
  created_at: ISOString;
};

export type SmartFormQuestionDTO =
  | TextQuestionDTO
  | SelectQuestionDTO
  | MultiChoiceQuestionDTO
  | FileUploadQuestionDTO;

export interface StudentSmartFormsDTO {
  student_id: number;
  smart_forms: SmartFormDTO[];
}
export const getAll = (): Promise<SmartFormDTO[]> => {
  return apiService.get("/customer_api/v2/smart_forms/");
};

export type SetAnswersDTO = {
  smart_form_template_id: string;
  student_id: number;
  answers: {
    question_template_id: string;
    answer: string | string[];
  }[];
  virtual_signature: string | null;
};
export const setAnswers = (dto: SetAnswersDTO): Promise<SmartFormDTO> => {
  return apiService.post("/customer_api/v2/smart_forms/set_answers", {
    data: dto,
  });
};
export const skip = (
  smart_form_template_id: string,
  student_id: number
): Promise<SmartFormDTO> => {
  return apiService.post("/customer_api/v2/smart_forms/skip", {
    data: {
      smart_form_template_id,
      student_id,
    },
  });
};
