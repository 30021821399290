import { defineRoute } from "../../util/typedRouting";
import { IBookingResult } from "./ScheduleSharingPageVM";

export const scheduleSuccessRoute = defineRoute<{
  state: {
    bookingResult: IBookingResult;
    emailsSent?: boolean;
  };
}>({
  path: "/thank-you",
  build: (path) => path,
});
