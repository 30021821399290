import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import {
  ICompanyProfileDTO,
  ICompanySettings,
  ICreditTypeDTO,
  ILocationDTO,
} from "../services/api";
import { InitRequestData } from "../services/api/common";

export default class CommonStore {
  constructor() {
    makeObservable(this);
  }

  @observable
  companyProfile!: ICompanyProfileDTO;

  @observable
  publicSettings!: ICompanySettings;

  @computed get paymentPlanPolicy() {
    return this.companyProfile.documents.payment_plan_policy;
  }

  @computed
  get creditTypesById() {
    return this.allCreditTypes.reduce<{
      [id: number]: ICreditTypeDTO;
    }>((map, creditType) => {
      map[creditType.id] = creditType;
      return map;
    }, {});
  }

  @computed
  get locationsById() {
    return this.allLocations.reduce<{
      [id: number]: ILocationDTO;
    }>((map, location) => {
      map[location.id] = location;
      return map;
    }, {});
  }

  @observable
  initialized: boolean = false;

  @observable isCompanyLocked: boolean = false;

  @observable
  private allCreditTypes: ICreditTypeDTO[] = [];

  @observable
  private allLocations: ILocationDTO[] = [];

  @action.bound
  initWith(data: InitRequestData) {
    runInAction(() => {
      this.allCreditTypes = data.credit_types;
      this.allLocations = data.locations;
      this.companyProfile = data.company_profile;
      this.publicSettings = data.public_settings;
      this.isCompanyLocked = data.is_company_locked;
    });

    runInAction(() => {
      this.initialized = true;
    });
  }
}
