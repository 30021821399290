import classNames from "classnames";

export const CompletenessPercentage = ({
  percentage,
  className,
}: {
  percentage: number;
  className?: string;
}) => (
  <span className={classNames("typography-main_sb", className)}>
    <span
      className={classNames({
        "text-icon-green-color": percentage === 100,
        "text-surface-orange-dark-color": percentage >= 50 && percentage < 100,
        "text-icon-red-color": percentage < 50,
      })}
    >
      {percentage.toFixed(0)}%
    </span>
  </span>
);
