import apiService from "./apiService";

export type IStripeConnectAccount =
  | {
      type: "connect";
      status: "NOT_ACTIVATED";
      email?: string;
      account_stripe_id: undefined;
    }
  | {
      type: "connect";
      status: "ACTIVATED";
      email: string;
      account_stripe_id: string;
    };
export interface IStripeDirectAccount {
  type: "direct";
  account_stripe_id: string;
  public_key: string;
}
export type TStripeConnectionStatus = "ACTIVATED" | "NOT_ACTIVATED";

export type TStripeAccount = IStripeConnectAccount | IStripeDirectAccount;

export const stripeAccount = (): Promise<TStripeAccount> =>
  apiService.get(`/customer_api/v2/integrations/stripe_account`);

export type TSignnowAccountStatus =
  | "NOT_CONNECTED"
  | "REQUIRES_CONFIGURATION"
  | "CONFIGURED";

export const signnowAccountStatus = (): Promise<TSignnowAccountStatus> =>
  apiService.get(`/customer_api/v2/integrations/stripe_account_id`);
