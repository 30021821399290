import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import RootStore from "./RootStore";
import api, { ICreditTypeDTO, IFunboxDTO, ILocationDTO } from "../services/api";
import { Notifier } from "../util/Notifier";

export default class FunboxStore {
  constructor(public rootStore: RootStore) {
    makeObservable(this);
  }

  onFunboxSelected = new Notifier<void>();

  //Locations
  @computed
  private get locationsIncludingArchived() {
    const locationsOfAllFunboxes: ILocationDTO[] = [];
    for (const location of this.funboxes.flatMap(
      (funbox) => funbox.active_locations,
    )) {
      if (locationsOfAllFunboxes.find((e) => e.id === location.id)) {
        continue;
      }
      locationsOfAllFunboxes.push(location);
    }
    return locationsOfAllFunboxes;
  }

  @computed
  get activeLocations() {
    return this.locationsIncludingArchived.filter((e) => !e.is_archived);
  }

  @observable
  private _selectedLocationId?: number;

  @computed
  get selectedLocation() {
    return this.activeLocations.find((e) => e.id === this._selectedLocationId);
  }

  @action.bound
  selectLocationId(
    id: number | undefined,
    options?: Partial<{ persist: boolean; resetSearchParams: boolean }>,
  ) {
    this._selectedLocationId = id;

    const persist = options?.persist ?? true;
    const resetSearchParams = options?.resetSearchParams ?? false;

    if (persist) {
      const locationKey = "selectedLocationId";
      if (id == null) {
        localStorage.removeItem(locationKey);
      } else {
        localStorage.setItem(locationKey, id.toString());
      }
    }

    if (resetSearchParams) {
      this.rootStore.routerStore.setSearchParam(LOCATION_KEY, undefined, true);
    }
  }

  //Funboxes
  @observable funboxes: IFunboxDTO[] = [];

  @observable
  private _selectedFunboxId?: string;

  @computed
  get selectedFunbox() {
    return this.funboxes.find((e) => e.id === this._selectedFunboxId);
  }

  @computed get isSelectedLocationExistsInSelectedFunbox() {
    const funbox = this.funboxes.find((fb) => fb.id === this._selectedFunboxId);
    if (!funbox || !this.selectedLocation?.id) return false;
    return Boolean(
      this.selectedLocation.id &&
        funbox.active_locations
          .map((l) => l.id)
          .includes(this.selectedLocation.id),
    );
  }

  @action.bound
  selectFunbox(funbox: IFunboxDTO) {
    localStorage.setItem("selectedFunboxId", funbox.id);
    if (!this.funboxes.find((e) => e.id === funbox.id)) {
      this.funboxes.push(funbox);
    }

    runInAction(() => {
      const isNew = funbox.id !== this._selectedFunboxId;
      this._selectedFunboxId = funbox.id;
      if (isNew) {
        this.onFunboxSelected.notify();
      }
    });

    if (this.selectedFunbox?.active_locations.length === 1) {
      this.selectLocationId(this.selectedFunbox.active_locations[0]?.id);
    }
    if (
      !this.selectedFunbox?.active_locations.find(
        (e) => e.id === this._selectedLocationId,
      )
    ) {
      this.selectLocationId(undefined);
    }

    this.loadCreditTypes(this._selectedFunboxId);
  }

  @computed
  get isBuyCreditsAndSaveEnabled() {
    return this.selectedFunbox?.is_buy_credits_and_save_enabled;
  }

  //Credits
  @observable
  creditTypes: ICreditTypeDTO[] = [];
  @computed
  get hasPaidCreditTypes() {
    return this.creditTypes.some(
      (e) => !e.is_free && !e.is_hidden_from_flexible_payments_page,
    );
  }

  @observable
  initialized: boolean = false;

  @observable
  isCreditTypesLoading = false;

  async getFunboxByIdOrUrlPart({
    id,
    url_part,
  }: {
    id?: string;
    url_part?: string;
  }): Promise<IFunboxDTO | undefined> {
    if (!id && !url_part) return undefined;
    let funbox = this.funboxes.find(
      (e) => (e.id && e.id === id) || (e.url_part && e.url_part === url_part),
    );
    if (funbox) return funbox;

    funbox = await (
      id
        ? api.common.funboxById(id)
        : api.common.funboxByUrlPart(url_part ?? "")
    ).catch(() => undefined);
    return funbox;
  }
  @action.bound
  async initWith(funboxes: IFunboxDTO[]) {
    const savedSelectedFunboxId =
      localStorage.getItem("selectedFunboxId") ?? undefined;

    let savedSelectedFunbox = funboxes.find(
      (f) => f.id === savedSelectedFunboxId,
    );
    if (!savedSelectedFunbox && savedSelectedFunboxId) {
      savedSelectedFunbox = await this.getFunboxByIdOrUrlPart({
        id: savedSelectedFunboxId,
      });
      if (!savedSelectedFunbox) localStorage.removeItem("selectedFunboxId");
    }

    const savedSelectedLocationId = +(
      localStorage.getItem("selectedLocationId") ?? ""
    );
    runInAction(() => {
      if (savedSelectedLocationId)
        this._selectedLocationId = savedSelectedLocationId;
      this.funboxes = funboxes;
    });

    if (funboxes.length === 1) {
      this.selectFunbox(funboxes[0]);
    } else if (savedSelectedFunbox) {
      this.selectFunbox(savedSelectedFunbox);
    }

    // this section selects funbox and location passed from url
    const locationIdFromUrl =
      this.rootStore.routerStore.currentSearch.get(LOCATION_KEY);
    if (locationIdFromUrl != null) {
      this.selectLocationId(parseInt(locationIdFromUrl), {
        resetSearchParams: true,
      });
    }
    const funboxIdFromUrl =
      this.rootStore.routerStore.currentSearch.get(FUNBOX_KEY);
    if (funboxIdFromUrl != null) {
      const funbox = await this.getFunboxByIdOrUrlPart({
        id: funboxIdFromUrl,
      });
      if (funbox) {
        this.selectFunbox(funbox);
      }

      this.rootStore.routerStore.setSearchParam(FUNBOX_KEY, undefined, true);
    }

    runInAction(() => {
      this.initialized = true;
    });
  }

  @action.bound
  private async loadCreditTypes(funboxId: string | undefined) {
    if (!funboxId) {
      this.creditTypes = [];
      return;
    }
    this.isCreditTypesLoading = true;
    try {
      const creditTypes = await api.common.funboxCreditTypes(funboxId);
      runInAction(() => {
        this.creditTypes = creditTypes;
      });
    } finally {
      this.isCreditTypesLoading = false;
    }
  }
}

const LOCATION_KEY = "location";
const FUNBOX_KEY = "funbox";
