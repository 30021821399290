import apiService from "./apiService";

export interface ICustomerPaymentMethodsDTO {
  stripe_payment_methods: ICustomerStripePaymentMethodDTO;
}

export interface ICustomerInvoicePaymentOptionDTO {
  description: string;
  is_description_provided: boolean;
}
export interface ICustomerStripePaymentMethodDTO {
  default_payment_method?: string;
  payment_methods: ICardDTO[];
}
export interface ICardDTO {
  last4: string;
  expiration_month: number;
  expiration_year: number;
  brand: string;
  payment_method_id: string;
  wallet_type: "apple_pay" | null;
}

export const get = (): Promise<ICustomerPaymentMethodsDTO> =>
  apiService.get("/customer_api/v2/payment_methods/");

export const remove = (id: string): Promise<void> =>
  apiService.delete(`/customer_api/v2/payment_methods/${id}`);

export const setDefault = (id: string): Promise<void> =>
  apiService.post(`/customer_api/v2/payment_methods/${id}/set_default`);

export const getSetupIntent = (): Promise<string> =>
  apiService.get("/customer_api/v2/payment_methods/setup_intent");
