import { ISOString } from "@sizdevteam1/funjoiner-uikit/types";
import apiService from "./apiService";
import { ICustomerDTO } from "./profile";

export interface IWaitlistRecordDTO {
  id: string;

  program_id: string;
  location_id: number;
  funbox_id: string;
  schedule_set_id: string;

  student_id: number;
  created_at: ISOString;
  start_date: ISOString;
  end_date: ISOString;
  program_type_name: string;
  schedule_set_name: string;

  waitlist_policy: string;
}

export interface IManageWaitlistDTO {
  program_id: string;
  student_ids: number[];
}
export const get = (): Promise<IWaitlistRecordDTO[]> =>
  apiService.get("/customer_api/v2/waitlists/");

export const addStudents = (
  scheduleSetId: string,
  dto: IManageWaitlistDTO
): Promise<ICustomerDTO> =>
  apiService.post(`/customer_api/v2/waitlists/${scheduleSetId}/add`, {
    data: dto,
  });

export const removeStudents = (
  schedule_set_id: string,
  dto: IManageWaitlistDTO
): Promise<ICustomerDTO> =>
  apiService.post(`/customer_api/v2/waitlists/${schedule_set_id}/remove`, {
    data: dto,
  });
