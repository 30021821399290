import { action, makeObservable, observable } from "mobx";
import RootStore from "./RootStore";
import api, { TDocumentDTO } from "../services/api";
import notificator from "../services/systemNotifications/notificationCenterService";
import { repeatWhile } from "../util/repeatUntil";
import { computedFn } from "mobx-utils";
import { ROUTES } from "./RouterStore";

export default class DocumentsStore {
  constructor(public rootStore: RootStore) {
    makeObservable(this);
  }

  @observable
  documents: TDocumentDTO[] = [];

  @observable
  initialized = false;

  @action
  loadDocuments = async () => {
    this.documents = await api.documents.get();
    this.initialized = true;
  };

  @observable
  _polledDocumentIds = observable.set<string>();

  isWaitingForDocumentUpdate = computedFn((documentId: string) => {
    return this._polledDocumentIds.has(documentId);
  });

  waitUntilDocumentIsSigned = async (documentId: string): Promise<void> => {
    if (this.isSigned(documentId)) return;
    this._polledDocumentIds.add(documentId);

    try {
      await repeatWhile(
        () => this.refreshDocument(documentId),
        () => !this.isSigned(documentId),
      );
    } finally {
      this._polledDocumentIds.delete(documentId);
    }
  };

  isSigned = computedFn((documentId: string) => {
    return (
      this.documents.find((document) => document.id === documentId)?.signed ===
      true
    );
  });

  refreshDocument = async (documentId: string) => {
    const document = await api.documents.getById(documentId);
    const index = this.documents.findIndex(
      (document) => document.id === documentId,
    );

    if (index === -1) {
      this.documents.push(document);
    } else {
      this.documents[index] = document;
    }
  };

  @action
  getSigningLink = async (documentId: string) => {
    try {
      return await api.documents.getSigningLink(documentId);
    } catch (e) {
      notificator.error("Failed to get signing link");
      throw e;
    }
  };

  signDocument = async (
    documentId: string | undefined | TDocumentDTO,
    redirectUri: (document: TDocumentDTO) => string,
  ) => {
    const document =
      typeof documentId === "object"
        ? documentId
        : this.documents.find((document) => document.id === documentId);

    if (document == null || document.signed) {
      throw new Error("Document is already signed or undefined");
    }

    const redirectTo = encodeURIComponent(redirectUri(document));

    switch (document.type) {
      case "custom_document":
        this.rootStore.routerStore.navigate(ROUTES.CUSTOM_DOCUMENT, {
          id: document.id,
          searchParams: {
            redirect_to: redirectTo,
          },
        });
        break;
      default: {
        const url = await this.getSigningLink(document.id);
        window.location.href = url + `?redirect_uri=${redirectTo}`;
        break;
      }
    }
  };
}
