import { defineRoute } from "../../util/typedRouting";
import { IScheduleAndPayOrderDTO } from "../../services/api/orders";

export const invoiceSentSuccessRoute = defineRoute<{
  state: {
    order: IScheduleAndPayOrderDTO;
  };
}>({
  path: "/invoice_sent_success",
  build: (path) => path,
});
