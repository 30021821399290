import { defineRoute } from "../../util/typedRouting";

export const afterScheduleQuestionSetsRoute = defineRoute<{
  state: {
    newAttendanceIds: string[];
  };
}>({
  path: "/after-schedule-questions/",
  build: (path) => path,
});
