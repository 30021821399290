import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  ConfirmationResult,
} from "firebase/auth";
import * as firebase from "firebase/app";
import { FIREBASE_CONFIG } from "../../config";
import notificator from "src/services/systemNotifications/notificationCenterService";
import * as Sentry from "@sentry/react";

firebase.initializeApp(FIREBASE_CONFIG);

class FirebasePhoneAuth {
  constructor() {
    const recaptchaContainer = this.getRecaptchaContainer();
    this._recaptchaVerifier = new RecaptchaVerifier(
      recaptchaContainer,
      {
        size: "invisible",
        callback: () => {
          recaptchaContainer.style.visibility = "hidden";
        },
      },
      getAuth(),
    );
  }

  async getConfirmationObject(phoneNumber: string) {
    try {
      return await signInWithPhoneNumber(
        getAuth(),
        phoneNumber,
        this._recaptchaVerifier,
      );
    } catch (e) {
      if (
        isFirebaseError(e) &&
        [
          "auth/too-many-requests",
          "auth/quota-exceeded",
          "auth/error-code:-39",
        ].includes(e.code)
      ) {
        notificator.error("Too many requests. Please try again later.");
      } else {
        notificator.error(e, { title: "Sign in failed!" });
      }
      if (!isFirebaseError(e)) {
        Sentry.captureException(e);
      }
    }
  }

  async confirm(
    code: string,
    confirmationObject: ConfirmationResult,
  ): Promise<string> {
    return await confirmationObject
      .confirm(code)
      .then((result) => result.user.getIdToken());
  }
  private readonly _recaptchaVerifier;

  private getRecaptchaContainer() {
    const recaptchaContainerId = "recaptcha-verifier";

    const existingContainer = document.getElementById(recaptchaContainerId);
    let recaptchaContainer: HTMLElement;
    if (existingContainer != null) {
      recaptchaContainer = existingContainer;
    } else {
      recaptchaContainer = document.createElement("div");
      recaptchaContainer.setAttribute("id", recaptchaContainerId);
      document.body.insertBefore(recaptchaContainer, document.body.firstChild);
    }
    return recaptchaContainer;
  }
}

export default new FirebasePhoneAuth();

function isFirebaseError(e: unknown): e is firebase.FirebaseError {
  return typeof e === "object" && e != null && "code" in e;
}
