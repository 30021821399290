export async function downloadFile({
  apiCall,
  fileName,
}: {
  apiCall: () => Promise<Response>;
  fileName: string;
}) {
  let a: HTMLAnchorElement | null = null;
  try {
    const response = await apiCall();
    if (response.status !== 200) {
      throw new Error();
    }

    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    a = document.createElement("a");
    a.style.display = "none";
    a.href = url;

    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(url);
  } finally {
    if (a != null) document.body.removeChild(a);
  }
}
