import cn from "classnames";
import React from "react";

const InfoMessage: React.FC<{
  className?: string;
  children?: React.ReactNode;
}> = ({ children, className }) => (
  <div
    className={cn(
      "text-main_sb rounded-[10px] bg-card-color text-center text-gray-text-color shadow-big",
      className
    )}
  >
    {children}
  </div>
);
export default InfoMessage;
