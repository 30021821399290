import { defineRoute } from "../../util/typedRouting";
import { IBookingResult } from "./ScheduleSharingPageVM";

export const scheduleAndPaySuccessRoute = defineRoute<{
  state: {
    bookingResult: IBookingResult;
    emailsSent?: boolean;
    installmentId?: string;
  };
}>({
  path: "/schedule_and_pay_success",
  build: (path) => path,
});
