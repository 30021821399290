import "@sizdevteam1/funjoiner-uikit/icons/index.scss";
import "@sizdevteam1/funjoiner-uikit/styles/typography.scss";
import "@sizdevteam1/funjoiner-uikit/styles/theme.scss";
import "./index.css";

import React from "react";
import { createRoot } from "react-dom/client";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { storesContext } from "./hooks/useStores";
import { RootStore } from "./stores";
import { configure } from "mobx";
import {
  COMMIT_TIMESTAMP,
  PENDO_API_KEY,
  SENTRY_DSN,
  SENTRY_ENV,
  VERSION,
} from "./config";
import setupSentry from "./setup-sentry";
import { Location, useLocation } from "react-router-dom";
import { LocationState } from "./stores/RouterStore";
import { BrowserHistory } from "@remix-run/router";
import { BrowserRouter, useHistory } from "./BrowserRouter";
import { setupPendo } from "./services/pendo-service";

dayjs.extend(advancedFormat);

configure({
  enforceActions: "never",
});

console.log(`Commit timestamp: ${COMMIT_TIMESTAMP}
Version: ${VERSION}
`);

if (SENTRY_DSN) {
  setupSentry(SENTRY_DSN, SENTRY_ENV || "UNKNOWN");
}
if (PENDO_API_KEY) {
  setupPendo(PENDO_API_KEY);
}

type StoresProps = {
  history: BrowserHistory;
  location: Location<LocationState>;
  children: React.ReactNode;
};

const Stores = (props: { children: React.ReactNode }) => {
  const history = useHistory();
  const location = useLocation();
  return (
    <StoresProvider history={history} location={location}>
      {props.children}
    </StoresProvider>
  );
};

class StoresProvider extends React.Component<StoresProps> {
  rootStore: RootStore;
  constructor(props: StoresProps) {
    super(props);
    this.rootStore = new RootStore({
      history: props.history,
      initialLocation: props.location,
    });
  }

  shouldComponentUpdate(nextProps: Readonly<StoresProps>): boolean {
    /*
      In case, synchronization stops working with on a new React version, it might be the cause
      To me it is a pure function, which is now allowed to perform side effects.
      Though, I didn't find any non-deprecated method to override to keep both Store and render methods in sync.
    */
    this.rootStore.routerStore.syncLocation(nextProps.location);
    return true;
  }

  render() {
    return (
      <storesContext.Provider value={this.rootStore}>
        {this.props.children}
      </storesContext.Provider>
    );
  }
}

const RootComponent = () => {
  return (
    <BrowserRouter>
      <Stores>
        <App />
      </Stores>
    </BrowserRouter>
  );
};

const container = document.getElementById("root")!;
const root = createRoot(container);
root.render(<RootComponent />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
