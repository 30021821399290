import * as Sentry from "@sentry/react";
import { isAbortError } from "@sizdevteam1/funjoiner-uikit";
import { VERSION } from "./config";

export default function setupSentry(sentryDsn: string, sentryEnv: string) {
  Sentry.init({
    dsn: sentryDsn,
    release: VERSION,
    environment: sentryEnv,
    tracesSampleRate: 0.2,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    beforeSend(event, hint) {
      const error = hint.originalException;

      if (error && isAbortError(error)) {
        return null;
      }

      return event;
    },
    integrations: [
      Sentry.httpClientIntegration(),
      // Sentry.replayIntegration({
      //   networkDetailAllowUrls: [window.location.origin],
      // }),
      Sentry.captureConsoleIntegration({ levels: ["error", "warn", "assert"] }),
    ],
    sendDefaultPii: true,
    ignoreErrors: [
      // Random plugins/extensions
      "top.GLOBALS",
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      "originalCreateNotification",
      "canvas.contentDocument",
      "MyApp_RemoveAllHighlights",
      "http://tt.epicplay.com",
      "Can't find variable: ZiteReader",
      "jigsaw is not defined",
      "ComboSearch is not defined",
      "http://loading.retry.widdit.com/",
      "atomicFindClose",
      // Facebook borked
      "fb_xd_fragment",
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      "bmi_SafeAddOnload",
      "EBCallBackMessageReceived",
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      "conduitPage",

      //Firebase SDK
      "This browser doesn't support the API's required to use the Firebase SDK.",
      "auth/code-expired",
      //Stripe
      "Your card number is incomplete.",
      "Your card's expiration date is incomplete",
      "Your card has been declined.",
      "Your card number is invalid.",
      "Your card's security code is incorrect.",
      "Your card has insufficient funds.",
      // Our redirect to funjoin.com error
      "Object captured as promise rejection with keys: code, message, redirect",
      "with keys: code, message, redirect",
      // Resize observer
      "loop completed with undelivered notifications",
      "loop limit exceeded",
    ],
    denyUrls: [
      // google re-captcha
      /gstatic\.com/,
      // Analytics
      /luckyorange\.com/i,
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
  });
}
