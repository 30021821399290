export function repeatWhile<T>(
  fn: () => Promise<T>,
  condition: (result: T) => boolean,
  options?: {
    delay?: number;
    interval?: number;
    maxAttempts?: number;
  }
): Promise<T> {
  const { delay = 2000, interval = 5000, maxAttempts = 10 } = options ?? {};

  return new Promise<T>((resolve, reject) => {
    setTimeout(() => {
      let attempts = 0;

      const attempt = async () => {
        try {
          const result = await fn();
          attempts++;
          if (condition(result)) {
            if (attempts < maxAttempts) {
              setTimeout(attempt, interval);
            } else {
              reject("Max attempts reached");
            }
          } else {
            resolve(result);
          }
        } catch (e) {
          reject(e);
        }
      };

      attempt();
    }, delay);
  });
}
