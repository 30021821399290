import { ISOString } from "@sizdevteam1/funjoiner-uikit/types";
import dayjs from "dayjs";

export function isPolicyCompliant(
  entity: {
    isProgram: boolean;
    start: ISOString;
  },
  policy: {
    is_enabled: boolean;
    hours_before_program_start: number;
    hours_before_session_start: number;
  }
) {
  if (!policy.is_enabled) {
    return false;
  }

  const startsAt = dayjs(entity.start);
  const now = dayjs();
  const diffInHours = startsAt.diff(now, "hour");
  const hoursBeforeStart = entity.isProgram
    ? policy.hours_before_program_start
    : policy.hours_before_session_start;
  return diffInHours >= hoursBeforeStart;
}
