import { defineRoute } from "../../util/typedRouting";

export const applicationProcessPageRoute = defineRoute<{
  searchParams: {
    documentId?: string;
    programId: string;
    scheduleSetId: string;
    selectedParticipantIds: string;
  };
}>({
  path: "/application-process/",
  build: (path, params) => path,
});
