import React from "react";
import { DateTimeObject } from "src/util/groupSessions";
import dayjs from "dayjs";
import formatStartEndTime from "src/util/formatStartEndTime";
import classNames from "classnames";

const SessionRow = ({
  session,
  className,
}: {
  session: DateTimeObject;
  className?: string;
}) => {
  return (
    <div
      className={classNames(
        "typography-main flex justify-between py-2 pl-4 text-text-color",
        className
      )}
    >
      <div>{dayjs(session.date).format("MMM Do")}</div>
      <div className="text-gray">{formatStartEndTime(session)}</div>
    </div>
  );
};

export default SessionRow;
