import React from "react";
import { RootStore } from "../stores";

export const storesContext = React.createContext<RootStore | null>(null);

const useStores = () => {
  const rootStore = React.useContext(storesContext);
  if (rootStore == null) {
    throw new Error("No RootStore found in context");
  }
  return rootStore;
};
export default useStores;
