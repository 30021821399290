import apiService from "./apiService";

export type TOwnershipType =
  | "END_USER"
  | "COMPANY_PRIVATE"
  | "COMPANY_PUBLIC"
  | "PUBLIC";

export interface IStorageFileDTO {
  key: string;
  ownership_type: TOwnershipType;
  customer_id?: number;
  company_id: string;
  public_name: string;
  download_url: string;
  created_at: string;
}
export const uploadFile = async (
  file: File,
  ownership_type: TOwnershipType
): Promise<IStorageFileDTO> => {
  const formData = new FormData();
  formData.set("file", file);
  formData.set("ownership_type", ownership_type);

  return await apiService.post<IStorageFileDTO>(`/customer_api/v2/files/`, {
    data: formData,
  });
};
