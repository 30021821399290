import apiService from "./apiService";
import { SmartFormDTO } from "./smartForms";
import { TBookingDataItemDTO } from "./orders";
import { TDocumentDTO } from "./documents";

export const getUnansweredSmartFormsRequiredBeforeSchedule = (
  data: TBookingDataItemDTO[]
): Promise<SmartFormDTO[]> => {
  return apiService.post(
    "/customer_api/v2/required_for_attendance/get_unanswered_smart_forms_required_before_schedule",
    {
      data: { data },
    }
  );
};

export const getRequirementsByAttendanceIds = (
  attendanceIds: string[]
): Promise<{
  smart_forms: SmartFormDTO[];
  documents: TDocumentDTO[];
}> => {
  return apiService.get(
    "/customer_api/v2/required_for_attendance/get_requirements_by_attendance_ids",
    {
      searchParams: { attendance_ids: attendanceIds },
    }
  );
};
