import { observer } from "mobx-react-lite";
import { Button, Modal } from "@sizdevteam1/funjoiner-uikit";
import { useState } from "react";

export const NewAppVersionMessage = observer(
  ({ forceRefresh }: { forceRefresh: () => void }) => {
    const [isMinimized, setIsMinimized] = useState(false);

    if (isMinimized) {
      return (
        <div
          className={"fixed top-[20px] left-0 right-0 z-20 mx-auto w-[324px]"}
        >
          <div
            className={
              "border-1 flex flex-row items-center gap-[10px] rounded-[10px] border-solid border-separator-color bg-on-main-color p-4 shadow-big"
            }
          >
            <div className={"rounded-[50px] bg-main-color"}>
              <i
                className={
                  "icon rocket-icon m-[6px] h-[30px] w-[30px] bg-on-main-color"
                }
              />
            </div>
            <div className={"flex flex-1 flex-col"}>
              <div className={"typography-h3 text-text-color"}>
                Update Available!
              </div>
              <div className={"typography-small mt-[2px] text-text-color"}>
                Refresh Browser Required
              </div>
            </div>
            <Button onClick={forceRefresh} kind={"text"} color={"orange"}>
              Refresh
            </Button>
          </div>
        </div>
      );
    }
    return (
      <Modal
        className={"!z-[1000]"}
        containerClassName={"!min-w-[366px] !w-[366px]"}
      >
        <div className={"relative flex flex-col items-center text-center"}>
          <div className={"customer-hub-stars-icon absolute -top-2 -left-2"} />
          <Button
            className={"absolute top-0 right-0"}
            kind={"icon"}
            onClick={() => setIsMinimized(true)}
          >
            <i className={"icon minimize-icon"} />
          </Button>

          <div className={"rounded-[50px] bg-main-color"}>
            <i
              className={
                "icon rocket-icon m-3 h-[56px] w-[56px] bg-on-main-color"
              }
            />
          </div>
          <div className={"w-[292px]"}>
            <div className={"typography-h2 mt-4 text-text-color"}>
              Refresh Browser Required
            </div>
            <div className={"typography-main mt-2 text-center text-text-color"}>
              A new software version has
              <br />
              been released.
              <span className={"whitespace-pre-wrap"}>{"  "}</span>For your
              benefit please refresh your internet browser.
            </div>
          </div>
          <RefreshNowButton forceRefresh={forceRefresh} />
        </div>
      </Modal>
    );
  },
);

const RefreshNowButton = ({ forceRefresh }: { forceRefresh: () => void }) => {
  return (
    <Button
      onClick={forceRefresh}
      size={"big"}
      className={
        "group mt-6 w-full hover:!border-light-orange-color hover:!bg-light-orange-color hover:!text-on-main-color"
      }
      color={"orange"}
      kind={"outlined"}
    >
      Refresh Now
      <span className={"pointer-events-none relative"}>
        <div
          className={
            "invisible absolute left-3 -top-1 min-h-[40px] scale-[2] group-hover:visible"
          }
        >
          ✨
        </div>
      </span>
    </Button>
  );
};
