type Listener<T> = (value: T) => void;
export class Notifier<T> {
  listeners = Array<Listener<T>>();

  addListener = (listener: (event: T) => void) => {
    this.listeners.push(listener);
    return () => this.removeListener(listener);
  };

  removeListener = (listener: (event: T) => void) => {
    this.listeners.splice(this.listeners.indexOf(listener), 1);
  };

  notify = (value: T) => {
    for (const listener of this.listeners) {
      listener(value);
    }
  };
}
