import { Button } from "@sizdevteam1/funjoiner-uikit";
import React from "react";
import api, { IOrderDTO } from "src/services/api";
import notificator from "src/services/systemNotifications/notificationCenterService";
import { downloadFile } from "src/util/downloadFile";
import canDownloadPdfReceipt from "../../../../util/canDownloadPdfReceipt";

const DownloadReceiptButton: React.FC<{
  order: IOrderDTO;
}> = ({ order }) => {
  if (!canDownloadPdfReceipt(order)) {
    return null;
  }
  return (
    <Button
      autoLoading
      kind="text"
      data-cy="download-receipt-btn"
      className="mx-auto my-3 !w-fit"
      onClick={async () => {
        try {
          await downloadFile({
            apiCall: () => api.profile.receipt(order.id),
            fileName: `receipt-${order.id}.pdf`,
          });
        } catch (e) {
          notificator.error("Failed to download receipt");
        }
      }}
    >
      Download PDF Receipt
    </Button>
  );
};

export default DownloadReceiptButton;
