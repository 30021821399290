import { defineRoute } from "../../util/typedRouting";
import { TAttendanceDTO } from "../../services/api";

export const cancelCreditRoute = defineRoute<{
  state: {
    attendance: TAttendanceDTO;
  };
}>({
  path: "/cancel_credit",
  build: (path) => path,
});
