import { defineRoute } from "../../util/typedRouting";
import { SmartFormDTO } from "../../services/api/smartForms";

export const beforeScheduleQuestionsPageRoute = defineRoute<{
  state: {
    smart_forms: SmartFormDTO[];
  };
}>({
  path: "/schedule/before-schedule-question-sets",
  build: (path) => path,
});
