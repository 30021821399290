import React from "react";
import cn from "classnames";
import styles from "./styles.module.scss";

const SizedBox: React.FunctionComponent<{
  width?: number;
  height?: number;
  style?: React.CSSProperties;
}> = ({ width, height, style }) => (
  <div className={cn(styles.SizedBox)} style={{ width, height, ...style }} />
);

export default SizedBox;
