import classNames from "classnames";
import React from "react";
import { PaymentMethodListTile } from "src/components/PaymentMethods/PaymentMethodListTile";
import { capitalize } from "@sizdevteam1/funjoiner-uikit";
import { ICardDTO } from "../../services/api";

interface IProps {
  data: ICardDTO;
  trailing?: React.ReactNode;
  isDefault?: boolean;
  className?: string;
  onClick?: () => void;
}

const CardsListItem = ({
  data,
  trailing,
  className,
  isDefault,
  onClick,
}: IProps) => {
  return (
    <PaymentMethodListTile
      className={className}
      onClick={onClick}
      title={
        <div
          className={"flex flex-row items-center"}
          data-cy={`credit-card-${data.brand}-${data.last4}`}
        >
          <CreditCardName card={data} />
          {data.wallet_type === "apple_pay" && (
            <div>
              <i
                className={
                  "apple-pay-brand-icon ml-[6px] h-[17px] w-[27px] bg-contain bg-center bg-no-repeat"
                }
              />
            </div>
          )}
          {isDefault && (
            <div
              className={
                "typography-small__t ml-4 flex items-center justify-center rounded-[6px] bg-main-color px-2 text-white-color"
              }
            >
              Default
            </div>
          )}
        </div>
      }
      trailing={trailing}
    />
  );
};

export const CreditCardName = ({ card }: { card: ICardDTO }) => (
  <div
    className={"typography-main_sb flex flex-row items-center text-text-color"}
  >
    <CreditCardIcon card={card} />
    {brandName(card)}
    {card.last4 && ` • ${card.last4}`}
  </div>
);

export const CreditCardIcon = ({ card }: { card: ICardDTO }) => (
  <i className={classNames(brandIconClassName(card), "mr-1")} />
);

const brandIconClassName = (card: ICardDTO) => {
  switch (card.brand) {
    case "apple_pay":
      return "apple-pay-brand-icon";
    case "visa":
      return "visa-icon";
    case "mastercard":
      return "mastercard-icon";
    default:
      return "default-card-icon";
  }
};
const brandName = (card: ICardDTO) => {
  if (card.brand === "apple_pay") {
    return "Apple Pay";
  }
  return capitalize(card.brand);
};
export default CardsListItem;
