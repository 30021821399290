import { defineRoute } from "../../../util/typedRouting";

export type StudentQuestionSetAction = "edit" | "fill";

export type StudentQuestionSetPageParams = {
  pathParams: {
    student_id: string;
    smart_form_template_id: string;
  };
  searchParams: {
    action: StudentQuestionSetAction;
  };
};
export const studentQuestionSetRoute =
  defineRoute<StudentQuestionSetPageParams>({
    path: "/personal-information/:student_id/sets/:smart_form_template_id",
    build(path, params) {
      return path
        .replace(":student_id", params.student_id)
        .replace(":smart_form_template_id", params.smart_form_template_id);
    },
  });
