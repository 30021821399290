import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import { ConfirmationResult } from "firebase/auth";
import firebasePhoneAuth from "../services/auth/firebasePhoneAuth";
import * as Sentry from "@sentry/react";
import api from "../services/api";
import apiService from "../services/api/apiService";

export default class AuthStore {
  constructor() {
    apiService.auth.onAuthStateChanged(this._onAuthStateChanged);
    makeObservable(this);
    apiService.auth.initPromise.then(() => {
      this.initialized = true;
    });
  }

  @observable
  loggedIn = false;

  @observable
  initialized = false;

  @computed get waitingForSmsCode() {
    return this._confirmationResult != null;
  }
  @computed get waitingForEmailCode() {
    return this.emailAuthCodeId != null;
  }

  @action getPhoneNumberAuthCode = async (phoneNumber: string) => {
    this._confirmationResult =
      await firebasePhoneAuth.getConfirmationObject(phoneNumber);
  };

  @action signInWithSmsCode = async (code: string) => {
    if (!this._confirmationResult) {
      throw new Error("No confirmation object");
    }
    const token = await firebasePhoneAuth.confirm(
      code,
      this._confirmationResult,
    );
    await apiService.auth.signInWithFirebaseToken(token);
    runInAction(() => (this._confirmationResult = undefined));
  };

  @action changePhoneWithCode = async (code: string) => {
    if (!this._confirmationResult) {
      throw new Error("No confirmation object");
    }
    const token = await firebasePhoneAuth.confirm(
      code,
      this._confirmationResult,
    );
    const result = await api.profile.changePhoneWithFirebaseToken(token);
    runInAction(() => (this._confirmationResult = undefined));
    return result;
  };
  @action getEmailAuthCode = async (email: string) => {
    this.emailAuthCodeId = await apiService.auth.getEmailCode(email);
  };

  @action signInWithEmailCode = async (code: string) => {
    if (!this.emailAuthCodeId) {
      throw new Error("No email auth code id");
    }
    await apiService.auth.continueWithEmailCode(this.emailAuthCodeId, code);
    runInAction(() => (this.emailAuthCodeId = undefined));
  };
  @action changeEmailWithEmailCode = async (code: string) => {
    if (!this.emailAuthCodeId) {
      throw new Error("No email auth code id");
    }
    const result = await api.profile.changeEmailWithEmailCode(
      this.emailAuthCodeId,
      code,
    );
    runInAction(() => (this.emailAuthCodeId = undefined));
    return result;
  };

  @action signOut = async () => {
    try {
      await apiService.auth.logout();
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      throw e;
    }
  };

  @observable private _confirmationResult?: ConfirmationResult;
  @observable private emailAuthCodeId?: string;

  @action
  private _onAuthStateChanged = async (state: unknown | null) => {
    console.log(state);
    this.loggedIn = state != null;
  };
}
