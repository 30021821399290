export function groupByReducer<T>(key: keyof T) {
  return function (acc: Record<string, T[]>, item: T) {
    //@ts-expect-error doesn't typecheck
    (acc[item[key]] ?? (acc[item[key]] = [])).push(item);
    return acc;
  };
}

export default function groupBy<T>(items: T[], key: keyof T): T[][] {
  return Object.values(items.reduce(groupByReducer(key), {}));
}
