import QRCodeLib from "qrcode";
import React, { useEffect, useState } from "react";

interface IQRCodeProps {
  data: string;
}
let _nextUid = 0;
const _getId = () => "canvas-" + _nextUid++;

const QRCode: React.FC<IQRCodeProps> = (props) => {
  const [uid] = useState(_getId);

  useEffect(() => {
    QRCodeLib.toCanvas(
      document.getElementById(uid),
      props.data,
      { color: { light: "#E5F9FF" }, width: 200 },
      (error) => {
        if (error) throw error;
      },
    );
  }, [props.data, uid]);

  useEffect(() => () => document.getElementById(uid)?.remove(), [uid]);

  return <canvas id={uid} />;
};
export default QRCode;
