import cn from "classnames";
import React from "react";

export const StrikethroughDivider = ({
  children,
  className,
}: {
  children: string;
  className?: string;
}) => {
  return (
    <div className={cn("flex flex-row items-center gap-3", className)}>
      <div className={"h-[1px] flex-1 bg-separator-color"} />
      <div className={"typography-small text-gray-text-color"}>{children}</div>
      <div className={"h-[1px] flex-1 bg-separator-color"} />
    </div>
  );
};
